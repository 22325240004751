const {
  readField,
  readRichText,
  readMarkdown,
  readImage,
} = require('../fields/index')
const common = require('./common')

const component = (page, loc = 'en=AU') => ({
  ...common(page, page.fields.title, loc),
  id: page.sys.id,
  slug: readField(page.fields.slug, loc),
  publishDate: readField(page.fields.publishDate, loc),
  title: readField(page.fields.title, loc),
  description: readField(page.fields.description, loc),
  keywords: readField(page.fields.keywords, loc),
  categories: readField(page.fields.categories, loc),
  tags: readField(page.fields.tags, loc),
  featureImage: readImage(page.fields.featureImage, loc, page.sys.space.sys.id),
  content: readRichText(page.sys.id, page.fields.content, loc, page.sys.space.sys.id),
  data: readMarkdown(page.fields.data, loc),
  related: readField(page.fields.related, loc),
})

module.exports = component
module.exports.tryLoad = () => null

module.exports.load = (data, loc = 'en-AU') => {
  const pages = data.entries.filter(e => e.sys.contentType.sys.id === 'blog')
  const processedPages = pages.map(page => component(page, loc))
  const result = {}
  processedPages
    .filter(item => !item.publishDate || Date.now() > item.publishDate)
    .forEach(page => {
      result[page.id] = page
      result[page.slug] = page
      result[page.title] = page
    })
  return result
}
