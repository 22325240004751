const {
  readField,
  readBoolean,
  readImage,
  loadSubItems,
} = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, null, subField.fields.title, loc),
  title: readField(subField.fields.title, loc),
  introText: readField(subField.fields.introText, loc),
  featureImage: readImage(subField.fields.featureImage, loc, subField.sys.space.sys.id),
  productInformationStatements: loadSubItems(
    readField(subField.fields.productInformationStatements, loc),
    loc
  ),
  images: loadSubItems(readField(subField.fields.images, loc), loc),
  description: readField(subField.fields.description, loc),
  displayOrder: readField(subField.fields.displayOrder, loc),
  displayOnFrontPage: readBoolean(subField.fields.displayOnFrontPage, loc),
})

module.exports = component
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'product') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'product'
  )

  const processedLocations = locations.map(l => component(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
  })
  return result
}
