const { readField, readMarkdown } = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, null, subField.fields.cmdTitle, loc),
  cmsTitle: readField(subField.fields.title, loc),
  title: readField(subField.fields.displayTitle, loc),
  mainText: readMarkdown(subField.fields.mainText, loc),
  elementType: readField(subField.fields.elementType, loc),
  icon: readField(subField.fields.icon, loc),
  iconPosition: readField(subField.fields.style, loc),
  modes: readField(subField.fields.modes, loc),
  cssClassNames: readField(subField.fields.cssClassNames, loc),
  linkUrl: readField(subField.fields.linkUrl, loc),
  options: readField(subField.fields.options, loc),
})

module.exports = component
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'genericElement') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'genericElement'
  )

  const processedLocations = locations.map(l => component(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
  })
  return result
}
