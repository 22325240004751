const { loadItem } = require('../fields/index')
// const common = require('./common');

const component = (subField, loc = 'en=AU') => ({
  ...loadItem(subField, loc),
})

module.exports = component
module.exports.tryLoad = () => null

module.exports.load = (data, title, loc = 'en-AU') => {
  const assets = data.entries.map(asset => ({
    id: asset.sys.id,
    ...loadItem(asset, loc),
  }))
  if (title) {
    const results = assets.filter(x => x.cmsTitle === title)
    if (results) return results[0]
    return null
  }
  const result = {}
  assets.forEach(asset => {
    if (asset.cmsTitle) {
      result[asset.cmsTitle] = asset
    }
    result[asset.id] = asset
  })
  return result
}
