const { readImage } = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, subField.sys.id, null, loc),
  id: subField.sys.id,
  ...readImage(subField, loc, subField.sys.space.sys.id),
})

module.exports = component
module.exports.tryLoad = () => null

module.exports.load = (data, loc = 'en-AU') => {
  const assets = data.assets.map(asset => ({
    id: asset.sys.id,
    ...readImage(asset, loc, asset.sys.space.sys.id),
  }))
  const result = {}
  assets.forEach(page => {
    result[page.id] = page
    result[page.title] = page
  })
  return result
}
