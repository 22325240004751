const {
  readField,
  readBoolean,
  readImage,
  loadSubItems,
  readMarkdown
} = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, null, subField.fields.title, loc),
  title: readField(subField.fields.title, loc),
  rating: readField(subField.fields.rating, loc),
  avaitar: readField(subField.fields.avaitar, loc),
  author: readField(subField.fields.author, loc),
  date: readField(subField.fields.date, loc),
  content: readMarkdown(subField.fields.content, loc)  
})

module.exports = component
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'review') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'review'
  )

  const processedLocations = locations.map(l => component(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
  })
  return result
}
