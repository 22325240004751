const { readField, readMarkdown } = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, null, subField.fields.title, loc),
  displayMode: readField(subField.fields.displayMode, loc),
  icon: readField(subField.fields.icon, loc),
  title: readField(subField.fields.title, loc),
  body: readMarkdown(subField.fields.body, loc),
  showReadMode: readField(subField.fields.showReadMore, loc),
  customClassNames: readField(subField.fields.customClassNames, loc),
  iconFeatures: readField(subField.fields.iconFeatures, loc),
  options: readField(subField.fields.options, loc),
})

module.exports = component
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'iconTextWidget') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'iconTextWidget'
  )

  const processedLocations = locations.map(l => component(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
  })
  return result
}
