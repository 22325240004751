const { loadSubItems } = require('../fields/index')
const common = require('./common')

const component = (topBar, loc = 'en=AU') => ({
  ...common(topBar, topBar.sys.id, null, loc),
  leftPosition: loadSubItems(
    (topBar.fields.leftPosition && topBar.fields.leftPosition[loc]) || []
  ),
  middlePosition: loadSubItems(
    (topBar.fields.middlePosition && topBar.fields.middlePosition[loc]) || []
  ),
  rightPosition: loadSubItems(
    (topBar.fields.rightPosition && topBar.fields.rightPosition[loc]) || []
  ),
})

module.exports = component
module.exports.tryLoad = () => null

module.exports.load = (data, loc = 'en-AU') => {
  const topBar = data.entries.filter(
    e => e.sys.contentType.sys.id === 'topBar'
  )[0]
  if (topBar) {
    const result = component(topBar, loc)

    return result
  }
  return {
    leftPosition: [],
    middlePosition: [],
    rightPosition: [],
  }
}
