const ContentfulProd = {
  space: '3wza6zjg8unz',
  accessToken:
    'ruMIxPdhu4Zo7CO-DOXQ9OY-_Yw775-6tSUKtR8E7UU',
}

const ContentfulPreview = {
  host: 'preview.contentful.com',
  space: '3wza6zjg8unz',
  accessToken:
    '8sY5FUVhY3HQHgoHjNZzrJfWOTx8rTv-siCdhzyyvS4',
}

const AllowReload = true

module.exports = {
  ContentfulProd,
  ContentfulPreview,
  AllowReload,
}
