import {ACTION_SELECT_PRODUCT} from './actions';

const initialState = {
  selected: null
}

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case ACTION_SELECT_PRODUCT:
      return { ...state, selected: data };
    default:
      return state;
  }
};