import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
// import { reducer as ga } from '@ic-hosting/ich-react-redux-ga'
import app from './app'
import { reducer as contactForm } from '../components/ContactForm/actions/submitForm'
import contentful from '../data/contentful/reducer'
import products from './products/reducers'

export default combineReducers({
  app,
  contactForm,
  // ga,
  form: formReducer,
  contentful,
  products,
})
