const { readField, loadSubItems } = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, subField.sys.id, null, loc),

  content: loadSubItems(readField(subField.fields.content, loc)),
  header1: readField(subField.fields.header1, loc),
  header2: readField(subField.fields.header2, loc),
  header3: readField(subField.fields.header3, loc),
})

module.exports = component
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'trippleHeaderBlock') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'trippleHeaderBlock'
  )

  const processedLocations = locations.map(l => component(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
  })
  return result
}
