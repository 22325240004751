const { readField, loadSubItems, readImage } = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, null, subField.fields.title, loc),
  title: readField(subField.fields.title, loc),
  layout: readField(subField.fields.layout, loc),
  widgets: loadSubItems(readField(subField.fields.widgets, loc)),
  bgImage: readImage(subField.fields.bgImage, loc, subField.sys.space.sys.id),
  featureImage: readImage(subField.fields.featureImage, loc, subField.sys.space.sys.id),
  otherRows: loadSubItems(readField(subField.fields.otherRows, loc)),
})

module.exports = component
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'sectionWidget') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'sectionWidget'
  )

  const processedLocations = locations.map(l => component(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
  })
  return result
}
