const {
    readField,
    readMarkdown,
    readImage,
    readImages,
    loadSubItems,
    loadSubId
  } = require('../contentful/fields')
  const common = require('../contentful/types/common')
  
  const component = (page, loc = 'en=AU') => ({
    ...common(page, null, page.fields.title, loc),
    title: readField(page.fields.title, loc),
    subTitle: readField(page.fields.subTitle, loc),
    categoryTitle: readField(page.fields.categoryTitle, loc),
    slug: readField(page.fields.slug, loc),
    price: readField(page.fields.price, loc),
    shortDescription: readField(page.fields.shortDescription, loc),
    packSizes: readMarkdown(page.fields.packSizes, loc),
    applications: loadSubItems(
        (page.fields.applications && page.fields.applications[loc]) || []
      ),
    application: readField(page.fields.application, loc),
    country: readField(page.fields.country, loc),      
    description: readMarkdown(page.fields.description, loc),
    disclaimer: readMarkdown(page.fields.disclaimer, loc),
    technical: readMarkdown(page.fields.technical, loc),
    applictions: loadSubItems((page.fields.applications && page.fields.applications[loc]) || []),
    reviews: loadSubItems(
      (page.fields.reviews && page.fields.reviews[loc]) || []
    ),
    related: loadSubId(
        (page.fields.related && page.fields.related[loc]) || []
      ),
    featureImage: readImage(page.fields.featureImage, loc),
    images: readImages(page.fields.images, loc),
    ratioImage: readImage(page.fields.ratioImage, loc)

  })
  
  module.exports = component
  module.exports.type = 'annexProduct'
  module.exports.tryLoad = (subField, loc = 'en-AU') => {
    if (subField.sys.contentType.sys.id === 'annexProduct') {
      return component(subField, loc)
    }
    return null
  }
  
  module.exports.load = (data, loc = 'en-AU') => {
    const pages = data.entries.filter(e => e.sys.contentType.sys.id === 'annexProduct')
    const processedPages = pages.map(page => component(page, loc))
    const result = {}
    processedPages.forEach(page => {
      result[page.id] = page
      result[page.title] = page
      result[page.slug] = page
    })
    result.all = processedPages
    return result
  }
  