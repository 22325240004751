const {
  readField,
  readMarkdown,
  readImage,
  loadSubItems,
} = require('../contentful/fields')
const common = require('../contentful/types/common')

const component = (page, loc = 'en=AU') => ({
  ...common(page, null, page.fields.title, loc),
  title: readField(page.fields.title, loc),
  details: readMarkdown(page.fields.details, loc),
  date: readField(page.fields.date, loc),
  locations: loadSubItems(
    (page.fields.locations && page.fields.locations[loc]) || []
  ),
  iamge: readImage(page.fields.image, loc),
})

module.exports = component
module.exports.type = 'news'
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'news') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const pages = data.entries.filter(e => e.sys.contentType.sys.id === 'news')
  const processedPages = pages.map(page => component(page, loc))
  const result = {}
  processedPages.forEach(page => {
    result[page.id] = page
    result[page.title] = page
    result[page.slug] = page
  })
  result.all = processedPages
  return result
}
