const { readField, readMarkdown, readImages } = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, null, subField.fields.title, loc),
  title: readField(subField.fields.title, loc),
  caption: readMarkdown(subField.fields.caption, loc),
  images: readImages(subField.fields.images, loc, subField.sys.space.sys.id),
  effects: readField(subField.fields.effects, loc),
  customClassNames: readField(subField.fields.customClassNames, loc),
  options: readField(subField.fields.options, loc) || {},
})

module.exports = component
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'imageWidget') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'imageWidget'
  )

  const processedLocations = locations.map(l => component(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
  })
  return result
}
