const { readField, readImages } = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, null, subField.fields.title, loc),
  title: readField(subField.fields.title, loc),
  images: readImages(subField.fields.images, loc, subField.sys.space.sys.id),
})

module.exports = component
module.exports.type = 'photoGallery'
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'slide') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'photoGallery'
  )

  const processedLocations = locations.map(l => component(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
  })
  result.all = processedLocations
  return result
}
