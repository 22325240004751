const { readField } = require('../fields/index')
const common = require('./common')

const component = (footer, loc = 'en=AU') => ({
  ...common(footer, footer.sys.id, null, loc),
  copyright: readField(footer.fields.copyright, loc),
  leftPosition: this.loadSubItems(footer.fields.leftPosition[loc]),
  middlePosition: this.loadSubItems(footer.fields.middlePosition[loc]),
  rightPosition: this.loadSubItems(footer.fields.rightPosition[loc]),
})

module.exports = component
module.exports.tryLoad = () => null

module.exports.load = (data, loc = 'en-AU') => {
  const footer = data.entries.filter(
    e => e.sys.contentType.sys.id === 'footer'
  )[0]

  if (footer) {
    const result = component(footer, loc)

    return result
  }
  return {
    copyright: '',
    leftPosition: [],
    middlePosition: [],
    rightPosition: [],
  }
}
