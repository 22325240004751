import {ACTION_UPDATE_DATA} from './constants';
import ContentfulLoader from ".";

const newsType = require('../customTypes/news');
const productType = require('../customTypes/product');

const initialState = {
  data: new ContentfulLoader([newsType, productType]).generateState()
}

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case ACTION_UPDATE_DATA:
      return { data };
    default:
      return state;
  }
};