const {
  readField,
  readMarkdown,
  readImage,
  loadSubItems,
} = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, null, subField.fields.cmstitle, loc),
  body: readMarkdown(subField.fields.body, loc),
  title: readField(subField.fields.title, loc),
  style: readField(subField.fields.style, loc),
  backgroundImage: readImage(subField.fields.backgroundImage, loc, subField.sys.space.sys.id),
  options: readField(subField.fields.options, loc),
  showReadMode: readField(subField.fields.includeReadMoreLink, loc),
  readMoreLink: readField(subField.fields.readMoreLink, loc),
  readMoreText: readField(subField.fields.readMoreText, loc),
  subTitle: readField(subField.fields.subTitle, loc),
  featureTitle: readField(subField.fields.featureTitle, loc),
  customClassNames: readField(subField.fields.customClassNames, loc),
  subItems: loadSubItems(readField(subField.fields.subItems, loc)),
})

module.exports = component
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'textWidget') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'textWidget'
  )

  const processedLocations = locations.map(l => component(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
  })
  return result
}
