const { readField } = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, subField.sys.id, null, loc),
  customClassNames: readField(subField.fields.customClassNames, loc),
  style: readField(subField.fields.style, loc),
})

module.exports = component
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'horizontalBar') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'horizontalBar'
  )

  const processedLocations = locations.map(l => component(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
  })
  return result
}
