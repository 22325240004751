const slug = require('slug')
const md = require('markdown-it')({
  html: true, // Enable HTML tags in source
  xhtmlOut: false, // Use '/' to close single tags (<br />).
  // This is only for full CommonMark compatibility.
  breaks: true, // Convert '\n' in paragraphs into <br>
  langPrefix: 'language-', // CSS language prefix for fenced blocks. Can be
  // useful for external highlighters.
  linkify: true, // Autoconvert URL-like text to links

  // Enable some language-neutral replacement + quotes beautification
  typographer: true,

  // Double + single quotes replacement pairs, when typographer enabled,
  // and smartquotes on. Could be either a String or an Array.
  //
  // For example, you can use '«»„“' for Russian, '„“‚‘' for German,
  // and ['«\xA0', '\xA0»', '‹\xA0', '\xA0›'] for French (including nbsp).
  quotes: '“”‘’',
})

const loadSubItems = (field, loc = 'en-AU', type) => {
  if (field) {
    return field.map(subField => this.loadItem(subField, loc, type))
  }
  return null
}

const readMarkdown = (field, loc, options) => {
  let result = (field && md.render(field[loc])) || undefined
  if (options && options.nakedMarkdown) {
    const regex = /<p>|<\/p>/g
    result = result.replace(regex, '')
  }
  return result
}

const readField = (field, loc) => (field && field[loc]) || undefined

const loadSubId = (field, loc = 'en-AU') => {
  if (field) {
    return field.map(subField => ({
      id: subField.sys.id,
      title: readField(subField.fields.title, loc),
      slug: readField(subField.fields.slug, loc) || slug(
        readField(subField.fields.title || subField.fields.name || '', loc)
      ),
    }))
  }
  return null
}

const readBoolean = (field, loc) => field && field[loc]

const calcImageSize = file => options => {
  // console.log(options);
  const appliedOptions = {
    format: 'jpg',
    fit: 'fill', // pad: Resize the image to the specified dimensions, padding the image if needed.
    // fill: Resize the image to the specified dimensions, cropping the image if needed.
    // scale: Resize the image to the specified dimensions, changing the original aspect ratio if needed.
    // crop: Crop a part of the original image to fit into the specified dimensions.
    // thumb: Create a thumbnail from the image.
    focus: null,
    quality: 50,
    progressive: true,
    radius: 0,
    backgroundColor: 'ffffff',
    ...options,
  }
  const appliedWidth =
    appliedOptions.width ||
    (
      (file.details.image.width / file.details.image.height) *
      appliedOptions.height
    ).toFixed(0)
  const appliedHeight =
    appliedOptions.height ||
    (
      (file.details.image.height / file.details.image.width) *
      appliedOptions.width
    ).toFixed(0)
  const appliedFocus = appliedOptions.focus ? `&f=${appliedOptions.focus}` : ''
  return (
    `${file.url}?w=${appliedWidth}&h=${appliedHeight}&q=${
      appliedOptions.quality
    }&` +
    `${
      appliedOptions.format === 'jpg' && appliedOptions.progressive
        ? 'fl=progressive&fm=jpg'
        : `fm=${appliedOptions.format}`
    }&` +
    `fit=${
      appliedOptions.fit === 'thumb'
        ? `thumb&f=${appliedOptions.focus}`
        : appliedOptions.fit
    }&` +
    `r=${appliedOptions.radius}&` +
    `bg=rgb:${appliedOptions.backgroundColor}` +
    appliedFocus
  )
}

const readImage = (field, loc = "en-AU", space) => {
  try {
    if (field && field[loc]) {
      return {
        id: field[loc].sys.id,
        space,
        file: field[loc].fields.file[loc],
        title: field[loc].fields.title[loc],
        description:
          (field[loc].fields.description &&
            field[loc].fields.description[loc]) ||
          '',
        src: field[loc].fields.file[loc].url,
        size: calcImageSize(field[loc].fields.file[loc]),
      }
    }
    if (field) {
      return {
        id: field.sys.id,
        space,
        file: field.fields.file[loc],
        title: field.fields.title[loc],
        description:
          (field.fields.description && field.fields.description[loc]) || '',
        src: field.fields.file[loc].url,
        size: calcImageSize(field.fields.file[loc]),
      }
    }
  } catch (err) {
    console.log(loc);
    console.log(err);
    console.log(field);
    if(field.fields) {
      console.log(field.fields.file);
    }
  }

  return null
}

const readImages = (field, loc, space) => {
  if (field && field[loc]) {
    return field[loc].map(item => readImage(item, loc, space))
  }

  return null
}

const readRichTextitem = (parentId, parentIndex, field, loc, space) => {
  const id = `${parentId}_${parentIndex}_`
  switch (field.nodeType) {
    case 'document':
      return {
        id,
        space,
        type: 'richText',
        data: field.data,
        content: field.content.map((item, index) =>
          readRichTextitem(id, parentIndex + index + 1, item, loc, space)
        ),
      }
    case 'text':
      return {
        id,
        space,
        type: `richText-${field.nodeType}`,
        marks: field.marks.map(mark => mark.type),
        content: field.value,
      }
    case 'hyperlink':
      return {
        id,
        space,
        type: `richText-${field.nodeType}`,
        content: field.content.map((item, index) =>
          readRichTextitem(id, parentIndex + index + 1, item, loc, space)
        ),
        url: field.data.url,
      }
    case 'entry-hyperlink':
      return {
        id,
        space,
        type: `richText-${field.nodeType}`,
        content: field.content.map((item, index) =>
          readRichTextitem(id, parentIndex + index + 1, item, loc, space)
        ),
        data: field.data,
      }
    case 'embedded-asset-block':
    case 'asset-hyperlink':
      return {
        id,
        space,
        type: `richText-${field.nodeType}`,
        content: field.content.map((item, index) =>
          readRichTextitem(id, parentIndex + index + 1, item, loc, space)
        ),
        asset: readImage(field.data.target, loc, space),
      }
    case 'embedded-entry-inline':
    case 'embedded-entry-block':
      return {
        id,
        space,
        type: `richText-${field.nodeType}`,
        data: field.data,
        item: field.data.target,
        content: 'Sorry unsupported type', // this.loadItem(field.data.target, loc),
      }
    case 'paragraph':
    case 'blockquote':
    case 'ordered-list':
    case 'unordered-list':
    case 'list-item':
    default:
      return {
        id,
        space,
        type: `richText-${field.nodeType}`,
        content: field.content.map((item, index) =>
          readRichTextitem(id, parentIndex + index + 1, item, loc, space)
        ),
        data: field.data,
      }
  }
}

const readRichText = (parentId, field, loc) => {
  if (field && field[loc]) {
    if (field[loc].nodeType === 'document') {
      return readRichTextitem(parentId, 1, field[loc], loc)
    }
  }
  return null
}

const loadItem = (subField, loc = 'en-AU', type) => {
  const types = [
    'sectionWidget',
    'iconTextWidget',
    'textWidget',
    'socialIconWidget',
    'pageSection',
    'sectionRow',
    'trippleHeaderBlock',
    'boxedContent',
    'imageWidget',
    'horizontalBar',
    'simpleCollectionOfEntities',
    'simpleCollection',
    'buttonLink',
    'genericElement',
    'slideGroup',
    'slide',
    'product',
    'textElement',
    'photoGallery',
    "review",
  ]
  /* eslist-disable */
  return type
    ? type.load(subField, loc)
    : types.reduce(
        (result, t) =>
          result || require(`../types/${t}`).tryLoad(subField, loc),
        null
      ) || {
        id: subField.sys.contentType.sys.id,
        type: `unknown: ${subField.sys.contentType.sys.id}`,
      }
  /* eslist-enable */
}

module.exports.loadSubItems = loadSubItems
module.exports.readMarkdown = readMarkdown
module.exports.readField = readField
module.exports.readBoolean = readBoolean
module.exports.readImages = readImages
module.exports.readRichText = readRichText
module.exports.readRichTextitem = readRichTextitem
module.exports.readImage = readImage
module.exports.loadItem = loadItem
module.exports.loadSubId = loadSubId
