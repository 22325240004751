const { readField, loadSubItems } = require('../fields/index')
const common = require('./common')

const tagOtherContent = items => {
  const result = {}
  items.forEach(item => {
    result[item.id] = item
    result[item.title] = item
  })
  return result
}

const component = (page, loc = 'en=AU') => ({
  ...common(page, null, page.fields.title, loc),
  title: readField(page.fields.title, loc),
  description: readField(page.fields.description, loc),
  keywords: readField(page.fields.keywords, loc),
  sections: loadSubItems(
    (page.fields.sections && page.fields.sections[loc]) || []
  ),
  otherContent: tagOtherContent(
    loadSubItems(
      (page.fields.otherContent && page.fields.otherContent[loc]) || []
    )
  ),
})

module.exports = component
module.exports.tryLoad = () => null

module.exports.load = (data, loc = 'en-AU') => {
  const pages = data.entries.filter(e => e.sys.contentType.sys.id === 'page')
  const processedPages = pages.map(page => component(page, loc))
  const result = {}
  processedPages.forEach(page => {
    result[page.id] = page
    result[page.title] = page
  })
  return result
}
