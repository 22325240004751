const { readField, readImage, readImages } = require('../fields/index')
const common = require('./common')

const component = (page, loc = 'en=AU') => ({
  ...common(page, null, page.fields.SiteTitle, loc),
  siteTitle: readField(page.fields.siteTitle, loc),
  siteDescription: readField(page.fields.siteDescription, loc),
  keywords: readField(page.fields.keywords, loc),
  favIcon: readImage(page.fields.favIcon, loc, page.sys.space.sys.id),
  logo: readImage(page.fields.logo, loc, page.sys.space.sys.id),
  backgroundVideos: readImages(page.fields.backgroundVideos, loc, page.sys.space.sys.id),
  primaryPhone: readField(page.fields.primaryPhone, loc),
  primaryEmail: readField(page.fields.primaryEmail, loc),
  socialLinks: readField(page.fields.socialLinks, loc),
})

module.exports = component
module.exports.type = 'layoutSettings'
module.exports.tryLoad = () => null

module.exports.load = (data, loc = 'en-AU') => {
  const layouts = data.entries.filter(
    e => e.sys.contentType.sys.id === 'layoutSettings'
  )

  const processedLayouts = layouts.map(page => component(page, loc))

  const result = {}
  processedLayouts.forEach(page => {
    result[page.id] = page
    result[page.siteTitle] = page
  })
  return result
}
