const { readField, readImage } = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, null, subField.fields.title, loc),
  title: readField(subField.fields.title, loc),
  title2: readField(subField.fields.title2, loc),
  cta1Link: readField(subField.fields.cta1Link, loc),
  cta1Text: readField(subField.fields.cta1Text, loc),
  cta2Text: readField(subField.fields.cta2Text, loc),
  cta2Link: readField(subField.fields.cta2Link, loc),
  options: readField(subField.fields.options, loc),
  image: readImage(subField.fields.image, loc, subField.sys.space.sys.id),
})

module.exports = component
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'slide') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'slide'
  )

  const processedLocations = locations.map(l => component(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
  })
  return result
}
