const slug = require('slug')
const { readField } = require('../fields')

const component = (subField, itemSlug, titleField, loc) => ({
  id: subField.sys.id,
  type: subField.sys.contentType.sys.id,
  createdAt: subField.sys.createdAt,
  updatedAt: subField.sys.updatedAt,
  revision: subField.sys.revision,
  space: subField.sys.space.sys.id,
  slug: subField.fields.slug
    ? readField(subField.fields.slug, loc)
    : itemSlug ||
      slug(readField(titleField, loc) || subField.sys.id, {
        lower: true,
      }),
})

module.exports = component
