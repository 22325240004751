const { readField } = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, null, subField.fields.title, loc),
  buttonText: readField(subField.fields.buttonText, loc),
  subText: readField(subField.fields.subText, loc),
  buttonLink: readField(subField.fields.buttonLink, loc),
  style: readField(subField.fields.style, loc),
  customClassNames: readField(subField.fields.customClassNames, loc),
  options: readField(subField.fields.options, loc),
})

module.exports = component
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'buttonLink') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'buttonLink'
  )

  const processedLocations = locations.map(l => component(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
  })
  return result
}
