const { readField, readMarkdown, readBoolean } = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, null, subField.fields.title, loc),
  title: readField(subField.fields.title, loc),
  body: readMarkdown(
    subField.fields.body,
    loc,
    readField(subField.fields.options, loc)
  ),
  showTitle: readBoolean(subField.fields.showTitle, loc),
  titleElement: readField(subField.fields.titleElement, loc),
  titleClasses: readField(subField.fields.titleClasses, loc),
  showBody: readBoolean(subField.fields.showBody, loc),
  bodyElement: readField(subField.fields.bodyElement, loc),
  bodyClasses: readField(subField.fields.bodyClasses, loc),
  options: readField(subField.fields.options, loc),
})

module.exports = component
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'textElement') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'textElement'
  )

  const processedLocations = locations.map(l => component(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
  })
  return result
}
