const { readField, readImage, loadSubItems } = require('../fields/index')
const common = require('./common')

const component = (subField, loc = 'en=AU') => ({
  ...common(subField, null, subField.fields.sectionTitle, loc),
  sectionTitle: readField(subField.fields.sectionTitle, loc),
  rows: loadSubItems(readField(subField.fields.rows, loc)),
  sectionProperties: readField(subField.fields.sectionProperties, loc),
  classNames: readField(subField.fields.classNames, loc),
  anchorName: readField(subField.fields.anchorName, loc),
  backgroundImage: readImage(subField.fields.backgroundImage, loc, subField.sys.space.sys.id),
})

module.exports = component
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'pageSection') {
    return component(subField, loc)
  }
  return null
}

module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'pageSection'
  )

  const processedLocations = locations.map(l => component(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
  })
  return result
}
